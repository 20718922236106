import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';
import { BackButtonModule } from '@mt-ng2/back-button-module';

import { CommonService } from './services/common.service';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';


import { DynamicSearchFiltersModule } from '@mt-ng2/dynamic-search-filters';
import { auditLogModuleConfig } from './configs/audit-log.config';
import { AuditLogUserService } from './services/audit-log-user.service';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { ModalModule } from '@mt-ng2/modal-module';
import { NavModule } from '@mt-ng2/nav-module';


export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    exports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    ModalModule,
    MtDisableDuringHttpCallsModule,
    BackButtonModule,
    KeyboardShortcutModule,
    DynamicSearchFiltersModule,
    NavModule,
],
    imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MtDisableDuringHttpCallsModule,
    ModalModule,
    BackButtonModule,
    KeyboardShortcutModule,
    DynamicSearchFiltersModule,
    NavModule,
],
})
export class AdminPortalSharedModule {
    static forRoot(): ModuleWithProviders<AdminPortalSharedModule> {
        return {
            ngModule: AdminPortalSharedModule,
            providers: [
                KeyboardShortcutService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useExisting: AuditLogUserService },
            ],
        };
    }
}
